type Props = {
  height?: number;
  className?: string;
};
const Logo = ({ height, className }: Props) => (
  <svg
    className={`w-auto ${className ?? ""}`}
    viewBox="0 0 108 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: `${height ?? 20}px`,
    }}
  >
    <path
      d="M3.61 0H0v12c0 4.8 4.813 6 7.22 6s7.22-1.2 7.22-6V0h-3.61v12c0 1.8-1.504 3.3-3.61 3.3S3.61 13.8 3.61 12V0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.652 0v18h3.309v-6.3h3.159c3.24 0 5.866-2.62 5.866-5.85C30.986 2.62 28.36 0 25.12 0h-6.468Zm3.309 8.7h3.159a2.854 2.854 0 0 0 2.858-2.85c0-1.574-1.28-2.85-2.858-2.85h-3.16v5.7Zm49.638.3c0 1.491-1.212 2.7-2.708 2.7A2.704 2.704 0 0 1 66.184 9c0-1.491 1.212-2.7 2.707-2.7A2.704 2.704 0 0 1 71.6 9Zm-2.708-.75a.751.751 0 1 1-1.502-.002.751.751 0 0 1 1.502.002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.916 9c0 4.97-4.04 9-9.025 9-4.984 0-9.025-4.03-9.025-9s4.041-9 9.025-9c4.985 0 9.025 4.03 9.025 9Zm-3.008 0c0 3.314-2.694 6-6.017 6-3.323 0-6.016-2.686-6.016-6s2.693-6 6.016-6 6.017 2.686 6.017 6Z"
      fill="currentColor"
    />
    <path
      d="M95.365 18H108l-.602-2.7h-8.724v-5.1h6.618l-.601-2.7h-6.017V2.7h7.822l-.602-2.7h-10.53v18Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.332 18h-3.31V0h6.468c3.24 0 5.867 2.62 5.867 5.85 0 3.23-2.627 5.85-5.867 5.85h-3.158V18Zm3.158-9.3h-3.158V3h3.158a2.854 2.854 0 0 1 2.858 2.85c0 1.574-1.28 2.85-2.858 2.85Z"
      fill="currentColor"
    />
    <path
      d="M38.507 0c3.008 0 4.763 1 5.265 1.5l-1.053 2.55c-2.858-1.5-4.778-1.26-5.566-.75-2.58 1.672 1.035 3.676 3.54 5.064.416.23.802.444 1.123.636 2.256 1.35 3.008 2.4 3.008 4.5S42.87 18 38.809 18c-3.25 0-5.265-1-5.867-1.5l.903-2.55c3.85 1.68 5.898 1.2 6.92.45 1.934-1.421-.47-2.763-2.731-4.025-.841-.47-1.663-.928-2.234-1.375-1.203-.9-3.008-2.7-3.008-4.5 0-2.25 1.956-4.5 5.716-4.5ZM54.36 0c3.212 0 4.809 1 5.206 1.5l-1.042 2.1c-6.9-2.76-8.626 2.45-8.626 5.4s1.725 8.16 8.626 5.4l1.041 2.1c-.396.5-1.992 1.5-5.205 1.5-3.57 0-8.031-2.7-8.031-9s4.462-9 8.031-9Z"
      fill="currentColor"
    />
  </svg>
);

export default Logo;
