import Confetti from "react-confetti";
import { useWindowSize } from "@react-hookz/web";

import { Product } from "../../data/models/partials/Product";
import t from "../../i18n";
import { classNames } from "../../utils";
import ProductLogo from "../brand/ProductLogo";
import G2Icon from "../icons/G2Icon";
import Star from "../icons/Star";

type Props = {
  children: React.ReactNode;
  success?: boolean;
  rightSide?: "reviews";
  successMessage?: string;
  confettiOnSuccess?: boolean;
  product?: Product;
};

function Gradient() {
  return (
    <img
      src={new URL("img/gradient.png", import.meta.url).href}
      className="absolute top-0 left-0 w-full select-none dark:hidden"
      draggable="false"
    />
  );
}

export default function AuthLayout({ children, rightSide, success, confettiOnSuccess, product }: Props) {
  confettiOnSuccess = confettiOnSuccess ?? true;
  const { width, height } = useWindowSize();
  return (
    <>
      {success && confettiOnSuccess && (
        <Confetti width={width} height={height} numberOfPieces={60} gravity={0.02} className="!z-50" />
      )}
      <div
        className={classNames(
          `flex w-full bg-white flex-row dark:bg-gray-900`,
          rightSide ? "flex-col md:flex-row md:h-screen" : "h-screen",
        )}
      >
        <div
          className={classNames(
            "flex flex-col flex-1 h-full overflow-y-auto",
            !rightSide && "bg-gray-50 dark:bg-gray-900",
          )}
        >
          <div className="w-full max-w-[440px] px-4 flex flex-col items-start h-full py-8 justify-between mx-auto space-y-10">
            <a href="/">
              <ProductLogo product={product} className="text-black dark:text-white z-10 relative" />
            </a>
            <div className="flex flex-col w-full space-y-6 relative z-10">{children}</div>
            <div />
          </div>
          {!rightSide && <Gradient />}
        </div>

        {rightSide === "reviews" && (
          <div className="relative flex flex-col justify-center flex-1 h-full px-4 py-16 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <div className="w-full max-w-[400px] mx-auto space-y-10 relative z-10">
              <div className="w-full space-y-6">
                <div className="flex items-center space-x-2">
                  <G2Icon />
                  <div className="flex flex-col space-y-1">
                    <div className="text-sm text-gray-600 dark:text-gray-300">
                      <strong className="text-gray-900 dark:text-white">4.7</strong> / 5 from{" "}
                      <strong className="text-gray-900 dark:text-white">63+</strong> reviews
                    </div>
                    <div className="flex items-center space-x-0.5">
                      <Star />
                      <Star />
                      <Star />
                      <Star />
                      <Star half />
                    </div>
                  </div>
                </div>
                <div className="text-xl font-semibold text-gray-900 dark:text-white">
                  {t(
                    "Join thousands of customer-centric teams using interactive co-browsing for onboarding, support and sales.",
                  )}
                </div>
              </div>

              <ul className="flex flex-col space-y-6">
                <li className="flex flex-col justify-between w-full p-6 space-y-10 bg-[#d9f7fc] rounded-3xl">
                  <div className="flex flex-col items-start space-y-6">
                    <p className="text-base text-gray-900">
                      "{t("Our data shows Upscope has cut our onboarding team's call time by 27%, which is fantastic.")}
                      "
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <img
                      src={new URL("img/square-testimonial-portrait.jpg?width=100", import.meta.url).href}
                      className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                    />
                    <div className="flex flex-col">
                      <div className="text-sm font-semibold text-gray-900">Cainen Gerety</div>
                      <div className="text-sm text-gray-600">EX Sales Manager at Square</div>
                    </div>
                  </div>
                </li>
                {/*
                <li className='flex flex-col justify-between w-full p-6 space-y-10 bg-[#efeeff] rounded-3xl'>
                  <div className='flex flex-col items-start space-y-6'>
                    <p className='text-base text-gray-900'>"Our data shows Upscope has cut our onboarding team's call time by 27%, which is fantastic."</p>
                  </div>
                  <div className='flex items-center space-x-4'>
                    <img src='https://miro.medium.com/max/632/1*WKTKqP9EHDm0abFmMip-Gg.png' className='flex-shrink-0 object-cover w-10 h-10 rounded-full' />
                    <div className='flex flex-col'>
                      <div className='text-sm font-semibold text-gray-900'>Nikos Moraitakis</div>
                      <div className='text-sm text-gray-600'>CEO - Workable</div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
            <Gradient />
          </div>
        )}
      </div>
    </>
  );
}
