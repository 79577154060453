import { useState } from "react";
import { useForm } from "react-hook-form";

import { ApiRequests } from "../../../../../data/api-endpoints";
import t from "../../../../../i18n";
import { useAuthentication } from "../../../../../providers/AuthenticationProvider";
import { useModal } from "../../../../../providers/ModalProvider";
import { useSlideOver } from "../../../../../providers/SlideOverProvider";
import { useApi } from "../../../../../use/api";
import useRedirect from "../../../../../use/redirect";
import Form from "../../../../forms/Form";
import FormInput from "../../../../forms/FormInput";
import Input from "../../../../forms/Input";
import Button from "../../../../ui/Button";
import SlideOver from "../../../../ui/SlideOver";

function CreateTeamContent() {
  const { user, refresh } = useAuthentication();
  const { removeSlideOver } = useSlideOver();
  const { register, handleSubmit } = useForm<ApiRequests["up.teams.create"]>();
  const [teamErrors, setTeamErrors] = useState<Partial<Record<keyof ApiRequests["up.teams.create"]["team"], string>>>();
  const redirect = useRedirect();
  const { call, isLoading } = useApi("up.teams.create");
  const { addModal } = useModal();

  function onNotAllowed() {
    removeSlideOver();
    addModal({
      title: "Not allowed",
      content: "You are not allowed to create teams.",
    });
  }

  function onSubmit(data: ApiRequests["up.teams.create"]) {
    call(data).then((response) => {
      if (response.status === "error") {
        switch (response.error) {
          case "not_allowed":
            onNotAllowed();
            break;
          case "validation_error":
            setTeamErrors(response.teamErrors);
            break;
        }
      } else {
        removeSlideOver();
        refresh().then(() => {
          redirect(["up.settings.billing", { teamDomain: response.team.domain }]);
        });
      }
    });
  }

  return (
    <SlideOver.Content as={Form} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormInput label="Team domain" error={teamErrors?.domain}>
          <Input type="text" {...register("team.domain")} autoFocus suffix={`.${user?.emailDomain}`} />
        </FormInput>
      </div>
      <SlideOver.Footer>
        <Button type="submit" loading={isLoading}>
          Create team
        </Button>
      </SlideOver.Footer>
    </SlideOver.Content>
  );
}

export function startCreateTeamFlow(addSlideOver: ReturnType<typeof useSlideOver>["addSlideOver"]) {
  addSlideOver({
    title: t("Create a new team"),
    description: t("Create a new team to separate your users and billing."),
    content: <CreateTeamContent />,
  });
}
