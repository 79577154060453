import { forwardRef } from "react";

type FormProps = JSX.IntrinsicElements["form"];

const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => (
  <form ref={ref} {...props}>
    {props.children}
  </form>
));

export default Form;
