import { createContext, useState, useRef, useContext, useEffect, useCallback } from "react";
import SlideOver from "../components/ui/SlideOver";

type ProviderProps = {
  children: JSX.Element;
};

type SlideOverDetails = Omit<React.ComponentProps<typeof SlideOver>, "open" | "children" | "setOpen"> & {
  content: React.ReactNode;
  replace?: boolean;
};

const SlideOverContext = createContext({
  addSlideOver: (slideOver: SlideOverDetails) => false as boolean,
  removeSlideOver: () => {},
});

export function useSlideOver() {
  return useContext(SlideOverContext);
}

export function SlideOverProvider({ children }: ProviderProps): JSX.Element {
  const [slideOverDetails, setSlideOverDetails] = useState<SlideOverDetails | undefined>(undefined);
  const [slideOverOpen, setSlideOverOpen] = useState(false);

  const slideOverDetailsRef = useRef<SlideOverDetails>();
  slideOverDetailsRef.current = slideOverDetails;
  const currentSlideOverId = useRef<number>(0);

  const removeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const removeSlideOver = useCallback(() => {
    setSlideOverOpen(false);

    removeTimeoutRef.current = setTimeout(() => {
      setSlideOverDetails(undefined);
    }, 300);
  }, []);

  const addSlideOver = useCallback((slideOver: SlideOverDetails): boolean => {
    if (slideOverDetailsRef.current && !slideOver.replace) {
      return false;
    }

    setSlideOverDetails(slideOver);
    setSlideOverOpen(false);
    currentSlideOverId.current++;
    clearTimeout(removeTimeoutRef.current);
    setTimeout(() => {
      setSlideOverOpen(true);
    });

    return true;
  }, []);

  return (
    <SlideOverContext.Provider value={{ addSlideOver, removeSlideOver }}>
      {slideOverDetails && (
        <SlideOver {...slideOverDetails} open={slideOverOpen} setOpen={removeSlideOver}>
          {slideOverDetails.content}
        </SlideOver>
      )}
      {children}
    </SlideOverContext.Provider>
  );
}

export function Done() {
  const { removeSlideOver } = useSlideOver();
  useEffect(() => {
    removeSlideOver();
  }, [removeSlideOver]);
  return null;
}
