import { useCallback, useEffect, useRef, useState } from "react";
import { useSessionStorageValue } from "@react-hookz/web";

import api from "../../../api-client";
import t from "../../../i18n";
import { useModal } from "../../../providers/ModalProvider";
import FormInput from "../../forms/FormInput";
import Input from "../../forms/Input";

export default function PasswordModal() {
  const { value: doNotOffer } = useSessionStorageValue("doNotOfferPassword", {
    defaultValue: false,
  });
  const { addModal } = useModal();

  useEffect(() => {
    if (doNotOffer) return;

    addModal({
      title: t("Set a password"),
      cancelText: t("Not now"),
      confirmText: t("Set password"),
      content: <PasswordModalContent />,
    });
  }, [doNotOffer, addModal]);

  return null;
}

const PasswordModalContent = () => {
  const { set: setDoNotOffer } = useSessionStorageValue("doNotOfferPassword", {
    defaultValue: false,
  });
  const { addModal, removeModal, bindModalClick } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>();

  const onSubmit = useCallback(
    async (evt?: React.FormEvent) => {
      evt?.preventDefault();
      const response = await api("auth.password.set", {
        user: {
          password: inputRef.current?.value ?? "",
        },
      });

      if (response.success) {
        if (evt) removeModal();

        addModal({
          type: "success",
          title: t("Password set"),
          content: t("Your password has been set. You can now log in with your email and password."),
          replace: true,
        });
        return true;
      } else if (response.error === "password_not_needed") {
        return true;
      } else if (response.error === "password_missing") {
        setError(t("Please enter a password."));
        return false;
      } else {
        setError(response.userErrors.password);
        return false;
      }
    },
    [removeModal, addModal],
  );

  useEffect(() => {
    setTimeout(() => {
      // Wait for modal to open
      inputRef.current?.focus();
    }, 100);
  }, [inputRef]);

  useEffect(() => {
    bindModalClick((confirm: boolean) => {
      if (confirm) return onSubmit();
      else setDoNotOffer(true);
    });
  }, [bindModalClick, onSubmit, setDoNotOffer]);

  return (
    <form onSubmit={onSubmit}>
      <p>{t("Setting a password allows you to log into Upscope more quickly and securely.")}</p>
      <FormInput
        label={t("Password")}
        explanation={t("Make sure it is long and secure.")}
        className="text-left !mt-5 !mb-0"
        error={error}
      >
        <Input type="password" ref={inputRef} required autoComplete="new-password" />
      </FormInput>
      <button type="submit" className="hidden">
        Submit
      </button>{" "}
      {/* for enter key to submit form */}
    </form>
  );
};
