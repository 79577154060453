import { Stripe, loadStripe } from "@stripe/stripe-js";
import { createContext, useState, useContext, useCallback } from "react";

type ProviderProps = {
  children: JSX.Element;
};

const StripeContext = createContext({
  getStripe(apiKey: string): Promise<Stripe | undefined> {
    return Promise.resolve(undefined);
  },
});

export function useStripe() {
  return useContext(StripeContext);
}

export function StripeProvider({ children }: ProviderProps): JSX.Element {
  const [stripeInstances, setStripeInstances] = useState<Record<string, Stripe>>({});

  const getStripe = useCallback(
    async (apiKey: string): Promise<Stripe | undefined> => {
      if (stripeInstances[apiKey]) {
        return stripeInstances[apiKey]!;
      }

      const stripe = await loadStripe(apiKey);

      if (!stripe) {
        return;
      }
      setStripeInstances((stripeInstances) => ({ ...stripeInstances, [apiKey]: stripe }));
      return stripe;
    },
    [stripeInstances],
  );

  return <StripeContext.Provider value={{ getStripe }}>{children}</StripeContext.Provider>;
}
