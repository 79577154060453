import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Plausible from "plausible-tracker";

import useCachedObject from "../use/cached-object";
import logger from "../log";

const plausible =
  process.env.NODE_ENV === "production"
    ? Plausible({
        domain: "app.upscope.io,all-upscope.com",
        apiHost: "https://data.upscope.com",
      })
    : undefined;

const debug = logger("router");

export function LogRoute({ children }: { children: React.ReactNode }) {
  const detectedParams = useParams();
  const { pathname } = useLocation();

  const cachedParams = useCachedObject(detectedParams);

  useEffect(() => {
    let route = `/${routePrefix(location.hostname)}:${pathname}`;
    Object.entries(cachedParams).forEach(([key, value]) => {
      if (value) route = route.replace(value, `:${key}`);
    });
    trackPageview(route);
  }, [pathname, cachedParams]);

  return children;
}

function routePrefix(hostname: string): string {
  if (hostname.startsWith("hq.")) return "hq";

  if (hostname.includes("helloscreen")) return "hs";

  if (hostname.includes("userview")) return "uv";

  if (hostname.includes("livedocument")) return "ld";

  if (hostname.includes("cobrowsingapi")) return "co";

  return "up";
}

function trackPageview(route: string) {
  debug("Pageview: %s", route);
  plausible?.trackPageview({
    url: location.origin + route + location.search + location.hash,
  });
}

export function trackEvent(eventName: string, props: Record<string, any>) {
  debug("Event: %s", eventName);
  plausible?.trackEvent(eventName, { props });
}
