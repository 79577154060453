let loaded = false;
function loadSDK(apiKey) {
    if (typeof window.Upscope !== "undefined") {
        loaded = true;
        return;
    }
    const i = function (...args) {
        i.q.push(args);
    };
    i.q = [];
    // The real Upscope implementation is loaded below
    window.Upscope = i;
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = `https://code.upscope.io/${apiKey}.js`;
    document.head.appendChild(s);
    loaded = true;
}
const _Upscope = function (...instruction) {
    if (!loaded) {
        if (instruction[0] === "init") {
            const config = instruction[1];
            if (!config?.apiKey) {
                throw new Error("API key not specified");
            }
            loadSDK(config.apiKey);
        }
        else {
            throw new Error("Upscope is not loaded");
        }
    }
    return window.Upscope(...instruction);
};
export default _Upscope;
