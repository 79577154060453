import { useEffect } from "react";

export function useGoogleTagManager({
  companyId,
  userId,
  baa,
}: {
  companyId?: string | null;
  userId?: string | null;
  baa?: boolean | null;
}) {
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  useEffect(() => {
    (window as any).companyId = companyId;
    (window as any).userId = userId;
    (window as any).baa = baa;
  }, [companyId, userId, baa]);

  useEffect(() => {
    if (typeof userId !== "undefined") {
      addGoogleTagManager();
    }
  }, [userId]);
}

function addGoogleTagManager() {
  const w = window as any;
  w["dataLayer"] = w["dataLayer"] || [];
  w["dataLayer"].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
  var f = document.getElementsByTagName("script")[0]!,
    j = document.createElement("script"),
    dl = "dataLayer" != "dataLayer" ? "&l=" + "dataLayer" : "";
  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=GTM-KRWCSL6" + dl;
  f.parentNode!.insertBefore(j, f);
}
