import { SparklesIcon } from "@heroicons/react/24/solid";
import { useLocalStorageValue } from "@react-hookz/web";

import GamificationBox from "./GamificationBox";

const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;

export default function ExtensionPrompt() {
  const { value: lastClickedOn, set: setLastClickedOn } = useLocalStorageValue<string>("prompts:extension:last-click");
  const lastClickedOnDate = lastClickedOn ? new Date(lastClickedOn) : null;

  if (document.documentElement.getAttribute("data-upscope-extension-installed")) {
    return null;
  }

  if (lastClickedOnDate && new Date().getTime() - lastClickedOnDate.getTime() < SEVEN_DAYS) {
    return null;
  }

  const isChromeDesktop = navigator.userAgent.match(/Chrome\/[0-9]+/) && !navigator.userAgent.match(/Mobile/);
  const isEdgeDesktop = navigator.userAgent.match(/Edg\/[0-9]+/) && !navigator.userAgent.match(/Mobile/);
  const isFirefoxDesktop = navigator.userAgent.match(/Firefox\/[0-9]+/) && !navigator.userAgent.match(/Mobile/);

  if (!isChromeDesktop && !isEdgeDesktop && !isFirefoxDesktop) {
    return null;
  }

  function onClick() {
    setLastClickedOn(new Date().toISOString());
    if (isChromeDesktop || isEdgeDesktop) {
      window.open("https://chromewebstore.google.com/detail/chrome-screen-sharing/kedahopbcbdppafmfmilachnkehnagbm");
    } else if (isFirefoxDesktop) {
      window.open("https://addons.mozilla.org/en-US/firefox/addon/cobrowsing/");
    }
  }

  return (
    <GamificationBox onClick={onClick}>
      <SparklesIcon className="w-5 h-5 text-primary-700 mb-2" />
      <span>Easily share any website with friends or colleagues with our extension.</span>
    </GamificationBox>
  );
}
