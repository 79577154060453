export default function G2Icon() {
  return (
    <svg className="w-10 h-10" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_325_3800)">
        <path
          d="M23.6658 46.8093C36.3542 46.8093 46.6401 36.5234 46.6401 23.835C46.6401 11.1466 36.3542 0.860596 23.6658 0.860596C10.9774 0.860596 0.691406 11.1466 0.691406 23.835C0.691406 36.5234 10.9774 46.8093 23.6658 46.8093Z"
          fill="#FF492C"
        ></path>
        <path
          d="M33.6084 18.4589H29.6844C29.7901 17.8432 30.1714 17.4986 30.9434 17.108L31.6648 16.7405C32.9559 16.0788 33.6452 15.3298 33.6452 14.1076C33.6452 13.3403 33.3465 12.7337 32.7538 12.2972C32.161 11.8607 31.4626 11.6448 30.6447 11.6448C30.0121 11.6373 29.3909 11.8146 28.8573 12.1548C28.3197 12.4856 27.92 12.9129 27.6718 13.4459L28.8068 14.5855C29.2479 13.6941 29.8866 13.2576 30.7274 13.2576C31.4396 13.2576 31.8761 13.6251 31.8761 14.1352C31.8761 14.5625 31.6648 14.9163 30.8469 15.3298L30.3828 15.555C29.3765 16.065 28.6781 16.6486 28.2738 17.3102C27.8694 17.9719 27.6718 18.8036 27.6718 19.8098V20.0855H33.6084V18.4589ZM33.0846 21.9602H26.5874L23.3389 27.5844H29.836L33.0846 33.2131L36.3332 27.5844L33.0846 21.9602Z"
          fill="white"
        ></path>
        <path
          d="M23.898 31.3372C19.7626 31.3372 16.3946 27.9692 16.3946 23.8338C16.3946 19.6984 19.7626 16.3304 23.898 16.3304L26.4665 10.959C25.6208 10.7908 24.7604 10.7062 23.898 10.7063C16.6473 10.7063 10.7705 16.5831 10.7705 23.8338C10.7705 31.0845 16.6473 36.9614 23.898 36.9614C26.6766 36.9667 29.3843 36.0844 31.6266 34.4434L28.787 29.5177C27.4285 30.6912 25.6932 31.3371 23.898 31.3372Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_325_3800">
          <rect width="45.9487" height="45.9487" fill="white" transform="translate(0.691406 0.860596)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
