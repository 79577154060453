import { Product } from "../../../../../data/models/partials/Product";

import ExtensionPrompt from "./Extension";
import G2Prompt from "./G2Review";
import Streak from "./Streak";

import { HelloscreenGame } from "~core/gamification/helloscreen/userGamification";

type Props = {
  currentProduct?: Product;
  game?: HelloscreenGame | null;
};

export default function Gamification({ currentProduct, game }: Props) {
  if (typeof game === "undefined") {
    return null;
  }
  if (currentProduct !== "helloscreen") {
    return <ExtensionPrompt />;
  }
  if (game) {
    return <HelloScreenGamification game={game} />;
  }

  return <ExtensionPrompt />;
}

function HelloScreenGamification({ game }: { game: HelloscreenGame }) {
  switch (game.id) {
    case "confetti_streak":
      return (
        <Streak current={game.currentStreak} goal={game.goal}>
          Use HelloScreen every day for a week, and unlock confetti mode 🎉.
        </Streak>
      );
    case "g2_review":
      return <G2Prompt reward={game.reward} />;
  }
}
