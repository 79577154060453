import { useEffect } from "react";

export default function useIntercom({
  enable,
  userId,
  hashedId,
}: {
  enable?: boolean;
  userId?: string;
  hashedId?: string;
}) {
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  useEffect(() => {
    if (!enable) {
      return;
    }
    (window as any).intercomSettings = {
      app_id: "jjuq5mvv",
      user_id: userId ?? null,
      user_hash: hashedId ?? null,
      hide_default_launcher: true,
    };

    addIntercom();
  }, [userId, hashedId, enable]);
}

function addIntercom() {
  const w = window as any;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [] as any[];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/jjuq5mvv";
      const x = d.getElementsByTagName("script")[0]!;
      x.parentNode!.insertBefore(s, x);
    };
    if (d.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}
