import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FolderIcon } from "@heroicons/react/24/outline";
import { fuzzy } from "fast-fuzzy";
import { Fragment, useMemo } from "react";

import { classNames } from "../../utils";
import LoadingSpinner from "./LoadingSpinner";

export type Shortcut = {
  id: string;
  icon: React.ComponentType<{ className?: string }>;
  name: string;
  alternativeNames?: string[];
  onClick: () => void;
  verb?: string;
  priority?: number;
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  recentItems: Shortcut[];
  results: Shortcut[];
  actions: Shortcut[];
  query: string;
  setQuery: (query: string) => void;
  loading: boolean;
};

const FUZZY_THRESHOLD = 0.3;

export default function Shortcuts(props: Props) {
  const filteredResults = useMemo(() => [...props.results], [props.results]);
  const filteredRecentItems = useMemo(
    () => [...props.recentItems].filter((item) => !filteredResults.some((result) => result.id === item.id)),
    [props.recentItems, filteredResults],
  );
  const filteredActions = useMemo(() => [...props.actions], [props.actions]);
  const aggregateItems = useMemo(
    () =>
      [...filteredActions, ...filteredRecentItems, ...filteredResults]
        .map(
          (item) =>
            [
              item,
              Math.max(...[item.name, ...(item.alternativeNames || [])].map((name) => fuzzy(props.query, name))),
            ] as const,
        )
        .filter(([_, score]) => score > FUZZY_THRESHOLD)
        .sort((a, b) => (b[0].priority ?? 0) - (a[0].priority ?? 0))
        .sort((a, b) => b[1] - a[1])
        .map(([item]) => item),
    [props.query, filteredRecentItems, filteredResults, filteredActions],
  );

  return (
    <Transition show={props.open} as={Fragment} afterLeave={() => props.setQuery("")} appear>
      <Dialog as="div" className="z-60 relative" onClose={props.setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel
              className="mx-auto max-w-2xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black
                ring-opacity-5 transition-all dark:divide-gray-700 dark:bg-gray-900"
            >
              <Combobox
                onChange={(item: Shortcut | null) => {
                  item?.onClick();
                  props.setOpen(false);
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 size-5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-900 placeholder:text-gray-400 focus:ring-0
                      dark:text-white"
                    placeholder="Search..."
                    onChange={(event) => props.setQuery(event.target.value)}
                    autoFocus
                  />
                </div>
                <ComboboxOptions
                  static
                  className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto dark:divide-gray-700"
                >
                  {props.query === "" && filteredRecentItems.length > 0 && (
                    <Section title="Recently viewed">
                      {filteredRecentItems.map((item) => (
                        <Item key={item.id} {...item} />
                      ))}
                    </Section>
                  )}
                  {props.query === "" && filteredActions.length > 0 && (
                    <Section>
                      {filteredActions.map((item) => (
                        <Item key={item.id} {...item} />
                      ))}
                    </Section>
                  )}
                  {props.query !== "" && aggregateItems.length > 0 && (
                    <Section>
                      {aggregateItems.map((item) => (
                        <Item key={item.id} {...item} />
                      ))}
                    </Section>
                  )}
                </ComboboxOptions>

                {props.query !== "" &&
                  aggregateItems.length === 0 &&
                  (props.loading ? (
                    <div className="px-6 py-14 sm:px-14">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <div className="px-6 py-14 text-center sm:px-14">
                      <FolderIcon className="mx-auto size-6 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      <p className="mt-4 text-sm text-gray-900 dark:text-gray-200">
                        We couldn't find anything with that term. Please try again.
                      </p>
                    </div>
                  ))}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

function Section({ title, children }: { title?: string; children: React.ReactNode }) {
  return (
    <li className="list-none p-2">
      {title && <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500 dark:text-gray-200">{title}</h2>}
      <ul className="text-sm text-gray-700 dark:text-gray-400">{children}</ul>
    </li>
  );
}

function Item(item: Shortcut) {
  return (
    <ComboboxOption
      key={item.name}
      value={item}
      className={({ focus }) =>
        classNames(
          "flex cursor-default select-none items-center rounded-md px-3 py-2",
          focus && "bg-indigo-600 dark:bg-gray-800 text-white",
        )
      }
    >
      {({ focus }) => (
        <>
          <item.icon
            className={classNames("h-6 w-6 flex-none", focus ? "text-white" : "text-gray-400 dark:text-gray-500")}
            aria-hidden="true"
          />
          <span className="ml-3 flex-auto truncate">{item.name}</span>
          {focus && item.verb && <span className="ml-3 flex-none text-indigo-100 dark:text-gray-400">{item.verb}</span>}
        </>
      )}
    </ComboboxOption>
  );
}
