import { classNames } from "../../../../../utils";

import GamificationBox from "./GamificationBox";

type Props = {
  children: React.ReactNode;
  goal: number;
  current: number;
};

export default function Streak({ children, current, goal }: Props) {
  return (
    <GamificationBox>
      <div className="relative h-12 -ml-2 mb-1">
        <div
          className={classNames(
            "absolute h-0 border-b-2 border-secondary-200 top-1/2",
            current < 2 && "left-1/2 w-1/4",
            current === 2 && "left-1/4 w-1/2",
            current > 2 && current < goal - 2 && "left-0 w-3/4",
            current === goal - 1 && "left-0 w-3/4",
            current === goal - 2 && "left-0 w-full",
            current === goal && "left-0 w-1/2",
          )}
        />
        {goal > current + 2 && (
          <div className="absolute h-0 border-b-2 border-secondary-200 border-dashed top-1/2 right-0 w-1/4" />
        )}
        {current > 2 && (
          <Number state="past" position="left-0">
            {current - 2}
          </Number>
        )}
        {current > 1 && (
          <Number state="past" position="left-1/4">
            {current - 1}
          </Number>
        )}
        <Number state="current" position="left-1/2">
          {current}
        </Number>

        {current < goal && (
          <Number state="future" position="left-3/4">
            {current + 1}
          </Number>
        )}
        {current < goal - 1 && (
          <Number state="future" position="left-full">
            {goal}
          </Number>
        )}
      </div>
      <span>{children}</span>
    </GamificationBox>
  );
}

function Number({
  children,
  state,
  position,
}: {
  children: React.ReactNode;
  state: "current" | "past" | "future";
  position: string;
}) {
  return (
    <div
      className={classNames(
        `absolute h-5 w-5 text-white rounded-full top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 flex items-center
        justify-center`,
        state === "current" ? "ring-2 ring-primary-400 ring-offset-2" : "",
        state === "future" ? "bg-secondary-200" : "bg-secondary-400",
        position,
      )}
    >
      <span className="text-xs font-semibold">{children}</span>
    </div>
  );
}
