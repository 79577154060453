import { Fragment, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useMeasure, useWindowSize } from "@react-hookz/web";

import { totalZIndex } from "../../utils";

import type { LinkToParam } from "./Link";
import Link from "./Link";

function classNames(...classes: Array<string | boolean | undefined>): string {
  return classes.filter(Boolean).join(" ");
}

export type DropdownLink = {
  name: string;
  className?: string;
  icon?: React.ReactNode;
  color?: "red";
} & ({ onClick: () => void } | { to: LinkToParam; newTab?: boolean });

type Props = {
  children: React.ReactElement;
  links: DropdownLink[];
  title?: string;
  dontRenderOnEmpty?: boolean;
};

export default function Dropdown({ children, links, title, dontRenderOnEmpty }: Props) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [dropDownPosition, setDropDownPosition] = useState<{
    left?: string;
    top?: string;
    bottom?: string;
    right?: string;
    transformOrigin?: string;
    zIndex?: number;
  }>({});
  const [dropdownSize, dropDownRef] = useMeasure<HTMLDivElement>();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const menuRef = useRef<HTMLDivElement>(null);

  function onButtonClick(e: React.MouseEvent<any, MouseEvent>) {
    setMousePosition({ x: e.clientX, y: e.clientY });
  }

  useEffect(() => {
    if (!dropdownSize) return;

    let top, right, bottom, left;
    if (mousePosition.x + dropdownSize.width > windowWidth) right = `${windowWidth - mousePosition.x}px`;
    else left = `${mousePosition.x}px`;

    if (mousePosition.y + dropdownSize.height > windowHeight) bottom = `${windowHeight - mousePosition.y}px`;
    else top = `${mousePosition.y}px`;

    const transformOrigin = `${left ? "left" : "right"} ${top ? "top" : "bottom"}`;
    const zIndex = menuRef.current ? totalZIndex(menuRef.current) + 1 : 0;
    setDropDownPosition({ top, right, bottom, left, transformOrigin, zIndex });
  }, [dropdownSize, mousePosition, windowWidth, windowHeight, menuRef]);

  if (dontRenderOnEmpty && links.length === 0) return;

  return (
    <Menu as="div" ref={menuRef} className="inline">
      {({ open, close }) => (
        <>
          <MenuButton onClick={open ? undefined : onButtonClick} as="span">
            {children}
          </MenuButton>

          {createPortal(
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                className="fixed overflow-hidden mt-2 w-56 rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5
                  focus:outline-none"
                style={dropDownPosition}
                ref={dropDownRef}
              >
                {title && (
                  <div className="px-4 py-2 border-b dark:border-gray-900 bg-gray-100 dark:bg-gray-700" role="none">
                    <p className="text-sm font-semibold text-gray-500 dark:text-gray-300" role="none">
                      {title}
                    </p>
                  </div>
                )}
                <div className="py-1">
                  {links.map((link) => (
                    <MenuItem key={link.name}>
                      {({ active }: { active: boolean }) =>
                        "to" in link ? (
                          <Link
                            to={link.to}
                            className={classNames(
                              active ? "bg-gray-100 dark:bg-white/5" : "opacity-80",
                              link.color === "red" ? "text-red-700 dark:text-red-500" : "text-gray-700 dark:text-white",
                              "block px-4 py-2 text-sm",
                              link.className,
                            )}
                            newTab={link.newTab}
                          >
                            {link.icon}
                            {link.name}
                          </Link>
                        ) : (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100 dark:bg-white/5" : "opacity-80",
                              link.color === "red" ? "text-red-700 dark:text-red-500" : "text-gray-700 dark:text-white",
                              "block px-4 py-2 text-sm",
                              link.className,
                            )}
                            onClick={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              link.onClick();
                              close();
                            }}
                          >
                            {link.icon}
                            {link.name}
                          </a>
                        )
                      }
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>,
            document.body,
          )}
        </>
      )}
    </Menu>
  );
}

export function DropdownIcon() {
  return (
    <div
      className="border border-gray-200 dark:border-gray-600 rounded-full p-0.5 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700
        shrink-0"
      data-role="dropdown-icon"
    >
      <EllipsisVerticalIcon className="h-4 w-4" />
    </div>
  );
}
