import { createContext, useCallback, useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";

type ProviderProps = {
  children: JSX.Element;
};

type PreloadAs = "fetch" | "video";

const HeaderContext = createContext({
  preload: (as: PreloadAs, ...links: string[]) => {},
});

export function usePreload() {
  return useContext(HeaderContext).preload;
}

export function PreloadProvider({ children }: ProviderProps): JSX.Element {
  const [linksToPreload, setLinksToPreload] = useState<[PreloadAs, string][]>([]);
  const [completedPreloads, setCompletedPreloads] = useState<string[]>([]);
  const completedPreloadsRef = useRef(completedPreloads);
  completedPreloadsRef.current = completedPreloads;

  const next = linksToPreload[0];

  const preload = useCallback((as: PreloadAs, ...links: string[]) => {
    links = links.filter((link) => !completedPreloadsRef.current.includes(link));
    setLinksToPreload((existing) => [
      ...links.map((link) => [as, link] as [PreloadAs, string]),
      ...existing.filter(([_, link]) => !links.includes(link)),
    ]);
  }, []);

  const onLoad = useCallback(() => {
    if (!next) {
      return;
    }
    setCompletedPreloads((existing) => [next[1], ...existing]);
    setLinksToPreload((existing) => existing.slice(1));
  }, [next]);

  return (
    <HeaderContext.Provider value={{ preload }}>
      {children}
      {next?.[0] === "fetch" &&
        createPortal(<link rel="preload" crossOrigin="" href={next[1]} as={next[0]} onLoad={onLoad} />, document.head)}
      {next?.[0] === "video" && (
        <video
          src={next[1]}
          onCanPlay={onLoad}
          preload="auto"
          className="absolute hidden w-1 h-1 pointer-events-none"
        />
      )}
    </HeaderContext.Provider>
  );
}
