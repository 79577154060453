import { StarIcon } from "@heroicons/react/20/solid";

import api from "../../../../../api-client";
import { useModal } from "../../../../../providers/ModalProvider";

import GamificationBox from "./GamificationBox";

export default function G2Prompt({ reward }: { reward: "confetti_unlock" }) {
  const { addModal } = useModal();

  function onLeftReview() {
    api("hs.gamification.leftReview.report");

    addModal({
      title: "Thank you for leaving a review!",
      content: "Thank you for leaving a review on G2. You have now unlocked confetti mode!",
      type: "success",
      replace: true,
    });
  }

  function onClick() {
    window.open("https://www.g2.com/products/upscope/reviews/start");

    setTimeout(() => {
      addModal({
        title: "Were you able to leave a review?",
        content: "Were you able to leave a review on G2? If you need a hand, please let us know!",
        confirmText: "I left a review",
        cancelText: "Not yet",
        onClick(leftReview: boolean) {
          if (leftReview) {
            onLeftReview();
          }
        },
      });
    }, 10_000);
  }

  return (
    <GamificationBox onClick={onClick}>
      <div className="flex items-center gap-1 mb-2">
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
      </div>
      <span>Leave a quick review of HelloScreen on G2 and unlock confetti mode 🎉.</span>
    </GamificationBox>
  );
}
