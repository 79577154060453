import { useDebouncedEffect } from "@react-hookz/web";
import { isEqual } from "lodash";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";

import ApplicationLayout from "../../components/layouts/Application";
import PasswordModal from "../../components/layouts/partials/PasswordModal";
import type { Product } from "../../data/models/partials/Product";
import { productName } from "../../formatters/billing";
import t from "../../i18n";
import { useApiData } from "../../use/api";
import { useAuthentication } from "../AuthenticationProvider";
import { useNotifications } from "../NotificationsProvider";
import { getCurrentProduct, SidebarItem, usePrimaryNavigation, useSecondaryNavigation } from "./application/navigation";

type ApplicationLayoutOptions = Pick<
  React.ComponentProps<typeof ApplicationLayout>,
  "hiddenSidebar" | "fixedHeight" | "currentTitle"
> & {
  currentRoute?: SidebarItem["id"];
};

const ApplicationLayoutContext = createContext({
  configureLayout: (options: ApplicationLayoutOptions) => {},
  currentProduct: undefined as Product | undefined,
});

export function useApplicationLayout() {
  return useContext(ApplicationLayoutContext);
}

export default function ApplicationLayoutProvider({ children }: { children: React.ReactNode }) {
  const [applicationLayoutOptions, setApplicationLayoutOptions] = useState<ApplicationLayoutOptions>({});
  const [applicationLayoutOptionsDebounced, setApplicationLayoutOptionsDebounced] = useState<ApplicationLayoutOptions>(
    {},
  );

  const { user, logout, teams, teamMemberships, impersonating, promptForPassword, adminRoles } = useAuthentication();
  const notifications = useNotifications();

  const location = useLocation();

  const route = useMatch(location.pathname);

  const configureApplicationLayout = useCallback((options: Partial<ApplicationLayoutOptions>) => {
    setTimeout(() => {
      // Cannot be called synchronously, otherwise it will cause a re-render loop
      setApplicationLayoutOptions((previousOptions) => {
        if (isEqual({ ...previousOptions, ...options }, previousOptions)) return previousOptions;

        return { ...previousOptions, ...options };
      });
    });
  }, []);

  useEffect(() => {
    setApplicationLayoutOptions({});
  }, [route]);

  useDebouncedEffect(
    () => {
      setApplicationLayoutOptionsDebounced(applicationLayoutOptions);
    },
    [applicationLayoutOptions],
    200,
  );

  const currentTeamId = useMemo(() => {
    return teams?.find((t) => location.pathname.split("/").includes(t.domain))?.id;
  }, [teams, location.pathname]);

  const currentProduct = useMemo(() => {
    return getCurrentProduct({
      teams: teams!,
      currentTeamId: currentTeamId,
      hostName: window.location.host,
    });
  }, [teams, currentTeamId]);

  const { game } = useApiData("hs.gamification.show", undefined, {
    enable: window.location.hostname.includes("helloscreen"),
  });

  const primaryNavigationItems = usePrimaryNavigation({
    isAdmin: user!.isAdmin,
    teams: teams!,
    teamMemberships: teamMemberships!,
    currentTeamId: currentTeamId,
    currentProduct: currentProduct,
    currentRoute: applicationLayoutOptionsDebounced.currentRoute,
    adminRoles,
  });

  const secondaryNavigationItems = useSecondaryNavigation({
    teams: teams!,
    currentTeamId: currentTeamId,
    primaryNavigation: primaryNavigationItems,
  });

  const availableProducts = useMemo(() => {
    return new Set(
      teams!
        .map((t) => t.products)
        .flat()
        .filter((p) => p !== currentProduct),
    );
  }, [teams, currentProduct]);

  const purchaseRequiredTeam = useMemo(() => {
    if (currentProduct === "livedocument") return null;

    return (
      teams?.find((t) => !t.isSubaccount && !t.paying && !t.trialEndsOn && !t.trialAvailable && !t.freeAccount) || null
    );
  }, [teams, currentProduct]);

  const bannedTeam = useMemo(() => {
    return teams?.find((t) => t.banned) || null;
  }, [teams]);

  const onTrialNoCardTeam = useMemo(() => {
    if (currentProduct === "livedocument") return null;

    return teams?.find((t) => t.trialEndsOn && !t.trialConvertsAutomatically) || null;
  }, [teams, currentProduct]);

  const trialNoCardEndsOn = useMemo(() => {
    return onTrialNoCardTeam?.trialEndsOn || null;
  }, [onTrialNoCardTeam]);

  return (
    <ApplicationLayoutContext.Provider
      value={{ configureLayout: configureApplicationLayout, currentProduct: currentProduct }}
    >
      {promptForPassword && <PasswordModal />}
      <ApplicationLayout
        currentTitle={applicationLayoutOptionsDebounced.currentTitle}
        auth={{ email: user!.email }}
        primaryNavigation={{ items: primaryNavigationItems }}
        secondaryNavigation={
          secondaryNavigationItems
            ? {
                title: "Teams",
                items: secondaryNavigationItems,
              }
            : undefined
        }
        product={currentProduct}
        availableProducts={availableProducts}
        fixedHeight={applicationLayoutOptionsDebounced.fixedHeight}
        onLogout={logout}
        hiddenSidebar={applicationLayoutOptionsDebounced.hiddenSidebar}
        gamification={currentProduct === "helloscreen" ? game : null}
        notifications={notifications}
        topBar={
          impersonating
            ? {
                content: `You are currently impersonating ${user!.name}`,
                cta: { label: "Stop impersonating", to: ["hq.impersonation.stop"] },
              }
            : bannedTeam
              ? {
                  content: t("Our team has been trying to contact you. Please get in touch to continue using Upscope."),
                  cta: { label: t("Contact us"), to: "mailto:team@upscope.com" },
                }
              : purchaseRequiredTeam && applicationLayoutOptionsDebounced["currentRoute"] !== "up.getting-started"
                ? {
                    content: t(
                      "Your free trial has ended. Please purchase a subscription to continue using %{product}",
                      {
                        product: currentProduct ? productName(currentProduct) : "Upscope",
                      },
                    ),
                    cta: {
                      label: t("Purchase"),
                      to: ["up.onboarding.purchase", { teamDomain: purchaseRequiredTeam.domain }],
                    },
                  }
                : onTrialNoCardTeam && applicationLayoutOptionsDebounced["currentRoute"] !== "up.getting-started"
                  ? {
                      content: t(
                        "Your free trial will end on %{formattedDate}. Add a card to continue using %{product}.",
                        {
                          product: currentProduct ? productName(currentProduct) : "Upscope",
                          formattedDate: trialNoCardEndsOn!.toLocaleDateString(),
                        },
                      ),
                      cta: {
                        label: t("Add payment method"),
                        to: ["up.onboarding.purchase", { teamDomain: onTrialNoCardTeam.domain }],
                      },
                    }
                  : undefined
        }
      >
        {children}
      </ApplicationLayout>
    </ApplicationLayoutContext.Provider>
  );
}
