import { Suspense, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import ApiErrorsListener from "./api-errors";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import t from "./i18n";
import { path } from "./paths";
import { useAuthentication } from "./providers/AuthenticationProvider";
import LayoutProvider from "./providers/LayoutProvider";
import { useModal } from "./providers/ModalProvider";
import { NotificationsProvider } from "./providers/NotificationsProvider";
import ShortcutsProvider from "./providers/ShortcutsProvider";
import { SlideOverProvider } from "./providers/SlideOverProvider";
import { getRoutes, RouteType } from "./route-list";
import useRedirect, { Redirect } from "./use/redirect";
import { LogRoute, trackEvent } from "./utils/LogRoute";

export default function Router() {
  return (
    <BrowserRouter>
      <SlideOverProvider>
        <NotificationsProvider>
          <LayoutProvider>
            <ApiErrorsListener>
              <ShortcutsProvider>
                <Suspense fallback={<LoadingSpinner />}>
                  <DrawRoutes />
                </Suspense>
              </ShortcutsProvider>
            </ApiErrorsListener>
          </LayoutProvider>
        </NotificationsProvider>
      </SlideOverProvider>
    </BrowserRouter>
  );
}

function DrawRoutes() {
  const { user, teams, teamMemberships } = useAuthentication();
  const { pathname } = useLocation();

  const generator = useCallback(
    (routePath: string, route: RouteType) => {
      const Element = route.component as React.ComponentType;
      let element = (
        <LogRoute>
          <Element />
        </LogRoute>
      );

      if (route.auth === "guest") {
        if (user) element = <Redirect to={["up.root"]} />;
      } else if (route.auth === "user") {
        if (!user) element = <Redirect to={["up.auth.loginRedirect"]} />;
      } else if (route.auth === "admin") {
        if (!user) element = <Redirect to={["up.auth.loginRedirect"]} />;
        else if (!user.isAdmin) element = <NoPermission />;
      } else if (route.auth) {
        const auth = route.auth;
        if (!user) {
          element = <Redirect to={["up.auth.loginRedirect"]} />;
        } else if (pathname.includes("/_/")) {
          const teamWithPermission = teamMemberships?.find((m) => (auth === "owner" ? m.isOwner : m.permissions[auth]));

          if (teamWithPermission)
            element = <Redirect to={pathname.replace("/_/", `/${teamWithPermission.team.domain}/`) as `/${string}`} />;
          else element = <NoPermission />;
        } else if (!(route.bypassAuthForAdmin && user.isAdmin)) {
          const currentTeam = teams!.find((t) => pathname.split("/").includes(t.domain));
          const membership = teamMemberships!.find((m) => m.team.id === currentTeam?.id);

          if (route.auth === "owner") {
            if (!membership?.isOwner) element = <NoPermission />;
          } else if (!membership?.isOwner && !membership?.permissions[route.auth]) {
            element = <NoPermission />;
          }
        }
      }

      // React router does not support multiple parameters in the same part of the path, or parameters that have
      // a prefix / suffix. We replace them with a generic name to avoid conflicts. Inside the component, we can then
      // deconstruct our multiParameter into the original parameters.
      routePath = routePath.replace(/[^/]+:[^/]+/g, ":multiParameter").replace(/\/:[^/]+-[^/]+/g, "/:multiParameter");

      return (
        <Route element={element} key={routePath} path={routePath}>
          {(route.children ?? []).map(([childPath, childRoute]) => generator(path(...childPath), childRoute))}
        </Route>
      );
    },
    [user, teams, teamMemberships, pathname],
  );

  return (
    <Routes>
      {getRoutes().map(([path, route]) => generator(path, route))}
      {/* When not found, reload to go to rails */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export function NoPermission() {
  const { addModal } = useModal();
  const redirect = useRedirect();
  useEffect(() => {
    addModal({
      title: t("Not authorized"),
      content: t("You are not authorized to access this page. Please check with your team owner."),
      type: "warning",
      onClick: () => {
        redirect(["up.root"]);
      },
    });
  }, [addModal, redirect]);

  useEffect(() => {
    trackEvent("unauthorized", {
      url: location.href,
      referrer: document.referrer,
    });
  }, []);
  return null;
}

export function MissingFeature() {
  const { addModal } = useModal();
  const redirect = useRedirect();
  useEffect(() => {
    addModal({
      title: t("You don't have this feature"),
      content: t(
        "Your team does not seem to have access to this feature. Get in touch with us if you would like to add this to your plan.",
      ),
      type: "warning",
      onClick: () => {
        redirect(["up.root"]);
      },
    });
  }, [addModal, redirect]);
  return null;
}

export function NotFound() {
  const { addModal } = useModal();
  const redirect = useRedirect();
  useEffect(() => {
    addModal({
      title: t("Not found"),
      content: t("We could not find this page. Please contact us if you think this is an error."),
      type: "info",
      onClick: () => {
        redirect(["up.root"]);
      },
    });
  }, [addModal, redirect]);

  useEffect(() => {
    trackEvent("not_found", {
      url: location.href,
      referrer: document.referrer,
    });
  }, []);

  return null;
}
