import { classNames } from "../../../../../utils";

export default function GamificationBox({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "bg-secondary-100 rounded-lg mb-4 shadow-multi p-2 -mx-2 text-sm text-primary-900 overflow-hidden",
        onClick && "clickable",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
