import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BuildingOffice2Icon, CurrencyDollarIcon, DocumentTextIcon, UserIcon } from "@heroicons/react/24/outline";
import { useDebouncedEffect, useLocalStorageValue } from "@react-hookz/web";

import api from "../api-client";
import Shortcuts, { Shortcut } from "../components/ui/Shortcuts";
import { ApiResponses, Successful } from "../data/api-endpoints";
import useCachedObject from "../use/cached-object";
import useRedirect from "../use/redirect";

type HistoryElement = {
  type: "user" | "company" | "quote" | "opportunity";
  id: string;
  name: string;
};

type SearchResult =
  | Successful<ApiResponses["hq.search"]>["companies"][number]
  | Successful<ApiResponses["hq.search"]>["users"][number]
  | Successful<ApiResponses["hq.search"]>["quotes"][number]
  | Successful<ApiResponses["hq.search"]>["opportunities"][number];

const ShortcutsContext = createContext({
  addAction: (shortcut: Shortcut) => {},
  removeAction: (id: string) => {},
  addToHistory: (historyElement: HistoryElement) => {},
  removeHistoryItem: (id: string) => {},
  toggleShortcuts: () => {},
});

export function useShortcuts() {
  return useContext(ShortcutsContext);
}

export default function ShortcutsProvider({ children }: { children: JSX.Element }): JSX.Element {
  // Only enable shortcuts within HQ
  if (!location.host.startsWith("hq.upscope")) return children;

  return <HqShortcutsProvider>{children}</HqShortcutsProvider>;
}

function HqShortcutsProvider({ children }: { children: JSX.Element }): JSX.Element {
  const redirect = useRedirect();
  const [actions, setActions] = useState<Shortcut[]>([]);
  const [results, setResults] = useState<Shortcut[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const queryRef = useRef(query);
  queryRef.current = query;
  const { value: historyElements, set: setHistoryElements } = useLocalStorageValue<HistoryElement[]>(
    "shortcuts-history",
    { defaultValue: [] },
  );
  const recentItems = useMemo(() => {
    return (historyElements || []).map((element) => historyElementToShortcut(element, redirect));
  }, [historyElements, redirect]);
  const addAction = useCallback((shortcut: Shortcut) => {
    setActions((actions) => [...actions, shortcut]);
  }, []);
  const removeAction = useCallback((id: string) => {
    setActions((actions) => actions.filter((action) => action.id !== id));
  }, []);
  const addToHistory = useCallback(
    (historyElement: HistoryElement) => {
      setHistoryElements((elements) =>
        [historyElement, ...(elements || []).filter(({ id }) => id !== historyElement.id)].slice(0, 5),
      );
    },
    [setHistoryElements],
  );
  const toggleShortcuts = useCallback(() => {
    setOpen((open) => !open);
  }, []);
  const removeHistoryItem = useCallback(
    (id: string) => {
      setHistoryElements((elements) => (elements || []).filter((element) => element.id !== id));
    },
    [setHistoryElements],
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "k" && event.metaKey) toggleShortcuts();
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [toggleShortcuts]);

  // Listen for 3 taps on mobile in short succession to open shortcuts
  useEffect(() => {
    let taps = 0;
    let lastTap = 0;
    const listener = () => {
      const now = Date.now();
      if (now - lastTap < 300) taps++;
      else taps = 1;

      lastTap = now;
      if (taps === 3) setTimeout(() => setOpen(true), 100);
    };
    window.addEventListener("touchstart", listener);
    return () => {
      window.removeEventListener("touchstart", listener);
    };
  }, []);

  useDebouncedEffect(
    () => {
      if (!query) return setResults([]);

      api("hq.search", { search: query })
        .then((response) => {
          if (queryRef.current !== query) return;

          if (response.users.length + response.companies.length + response.quotes.length === 0) return;

          setResults(
            [...response.users, ...response.companies, ...response.quotes].map((result) =>
              searchResultToShortcut(result, redirect),
            ),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [query],
    100,
  );

  useEffect(() => {
    if (!query) return;

    setLoading(true);
  }, [query]);

  return (
    <ShortcutsContext.Provider
      value={{
        addAction,
        removeAction,
        addToHistory,
        toggleShortcuts,
        removeHistoryItem,
      }}
    >
      <Shortcuts {...{ actions, open, setOpen, query, setQuery, recentItems, results, loading }} />
      {children}
    </ShortcutsContext.Provider>
  );
}

export function ShortcutAction(props: Shortcut) {
  const { addAction, removeAction } = useContext(ShortcutsContext);
  const cachedProps = useRef(props);

  useEffect(() => {
    const shortcut = cachedProps.current;
    addAction(shortcut);
    return () => {
      removeAction(shortcut.id);
    };
  }, [addAction, removeAction]);

  return null;
}

export function HistoryElement(props: SearchResult) {
  const { addToHistory } = useContext(ShortcutsContext);
  const cachedProps = useCachedObject(props);

  useEffect(() => {
    switch (cachedProps.type) {
      case "user":
        addToHistory({
          type: "user",
          id: cachedProps.id,
          name: userName(cachedProps),
        });
        break;
      case "company":
        addToHistory({
          type: "company",
          id: cachedProps.id,
          name: companyName(cachedProps),
        });
        break;
      case "quote":
        addToHistory({
          type: "quote",
          id: cachedProps.publicKey,
          name: quoteName(cachedProps),
        });
        break;
      case "sales_opportunity":
        addToHistory({
          type: "opportunity",
          id: cachedProps.id,
          name: opportunityName(cachedProps),
        });
        break;
    }
  }, [addToHistory, cachedProps]);

  return null;
}

function historyElementToShortcut(historyElement: HistoryElement, redirect: ReturnType<typeof useRedirect>): Shortcut {
  switch (historyElement.type) {
    case "user":
      return {
        id: historyElement.id,
        name: historyElement.name,
        icon: UserIcon,
        verb: "View",
        priority: -1,
        onClick: () => {
          redirect(["hq.users.show", { userId: historyElement.id }]);
        },
      };
    case "company":
      return {
        id: historyElement.id,
        name: historyElement.name,
        icon: BuildingOffice2Icon,
        verb: "View",
        priority: 2,
        onClick: () => {
          redirect(["hq.accounts.show", { companyId: historyElement.id }]);
        },
      };
    case "quote":
      return {
        id: historyElement.id,
        name: historyElement.name,
        icon: DocumentTextIcon,
        verb: "View",
        priority: 1,
        onClick: () => {
          redirect(["hq.quotes.show", { quotePublicKey: historyElement.id }]);
        },
      };
    case "opportunity":
      return {
        id: historyElement.id,
        name: historyElement.name,
        icon: CurrencyDollarIcon,
        verb: "View",
        priority: 1,
        onClick: () => {
          redirect(["hq.sales.opportunities.show", { opportunityId: historyElement.id }]);
        },
      };
  }
}

function searchResultToShortcut(result: SearchResult, redirect: ReturnType<typeof useRedirect>): Shortcut {
  switch (result.type) {
    case "user":
      return {
        id: result.id,
        name: userName(result),
        alternativeNames: [result.id, result.name, result.email].filter(Boolean),
        icon: UserIcon,
        verb: "View",
        priority: -1,
        onClick: () => {
          redirect(["hq.users.show", { userId: result.id }]);
        },
      };
    case "company":
      return {
        id: result.id,
        name: companyName(result),
        alternativeNames: [result.id, result.name, ...result.domains],
        icon: BuildingOffice2Icon,
        verb: "View",
        priority: 2,
        onClick: () => {
          redirect(["hq.accounts.show", { companyId: result.id }]);
        },
      };
    case "quote":
      return {
        id: result.publicKey,
        name: quoteName(result),
        icon: DocumentTextIcon,
        verb: "View",
        priority: 1,
        onClick: () => {
          redirect(["hq.quotes.show", { quotePublicKey: result.publicKey }]);
        },
      };
    case "sales_opportunity":
      return {
        id: result.id,
        name: opportunityName(result),
        icon: DocumentTextIcon,
        verb: "View",
        priority: 1,
        onClick: () => {
          redirect(["hq.sales.opportunities.show", { opportunityId: result.id }]);
        },
      };
  }
}

function companyName(company: Extract<SearchResult, { type: "company" }>): string {
  return company.name === company.domains[0] && company.domains.length > 0
    ? company.name
    : `${company.name} (${company.domains[0]})`;
}

function quoteName(quote: Extract<SearchResult, { type: "quote" }>): string {
  return `Quote ${quote.publicKey} for ${quote.company.name}`;
}

function opportunityName(opportunity: Extract<SearchResult, { type: "sales_opportunity" }>): string {
  return `Opportunity ${opportunity.id} for ${opportunity.company.name}`;
}

function userName(user: Extract<SearchResult, { type: "user" }>): string {
  return user.name ? `${user.name} (${user.email})` : user.email;
}
