import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

import DataTypes from "~data";

import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";

export type NotificationsListItem = {
  id: DataTypes["Notifications"]["id"];
  title: string;
  content: string;
  isNew: boolean;
  actionable: boolean;
  actions?: {
    primary?: { text: string; onClick: string };
    secondary?: { text: string; onClick: string };
  };
};

type Props = {
  notifications: NotificationsListItem[];
  hasMore: boolean;
  loadingMore: boolean;
  onReachEnd?: () => void;
  onAction: (notificationId: DataTypes["Notifications"]["id"], actionId: string) => Promise<void>;
  open: boolean;
};

export default function NotificationList({ onReachEnd, ...props }: Props) {
  const triggerLoadMoreRef = useRef<HTMLDivElement>(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!triggerLoadMoreRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        setIntersecting(entries[0]!.isIntersecting);
      },
      { threshold: 0.5 },
    );

    observer.observe(triggerLoadMoreRef.current);

    return () => {
      observer.disconnect();
    };
  }, [triggerLoadMoreRef]);

  useEffect(() => {
    if (intersecting && onReachEnd) onReachEnd();
  }, [onReachEnd, intersecting]);

  return (
    <Transition show={props.open}>
      <div
        className="fixed inset-0 -z-10 bg-neutral-500/50 transition-opacity ease-in-out data-[closed]:opacity-0 data-[open]:opacity-100
          dark:bg-neutral-900/50"
      />
      <div
        className="w-full max-w-96 flex-auto overflow-hidden rounded-md bg-white text-sm shadow-lg ring-1 ring-gray-900/5 transition
          data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150
          data-[enter]:ease-out data-[leave]:ease-in dark:bg-gray-700"
      >
        <div className="max-h-96 divide-y divide-gray-100 overflow-y-auto dark:divide-gray-600">
          {props.notifications.map((item) => (
            <div key={item.id} className="relative cursor-default p-2">
              <strong className="font-semibold text-gray-900 dark:text-gray-100">{item.title}</strong>
              <p className="mt-1 text-gray-600 dark:text-gray-200">{item.content}</p>
              {item.isNew && <div className="bg-secondary absolute right-2 top-2 size-[5px] rounded-full" />}

              {item.actions && (
                <div className="mt-2 flex gap-2">
                  {item.actions.primary && (
                    <Button
                      variant="action"
                      size="sm"
                      onClick={() => props.onAction(item.id, item.actions!.primary!.onClick)}
                      disabled={!item.actionable}
                    >
                      {item.actions.primary.text}
                    </Button>
                  )}
                  {item.actions.secondary && (
                    <Button
                      className="opacity-80"
                      variant="action"
                      size="sm"
                      onClick={() => props.onAction(item.id, item.actions!.secondary!.onClick)}
                      disabled={!item.actionable}
                    >
                      {item.actions.secondary.text}
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
          {props.hasMore && (
            <div className="relative p-5" ref={triggerLoadMoreRef}>
              {props.loadingMore && <LoadingSpinner />}
            </div>
          )}
          {!props.hasMore && props.loadingMore && (
            <div className="relative h-12">
              <LoadingSpinner />
            </div>
          )}
          {props.notifications.length === 0 && !props.loadingMore && !props.hasMore && (
            <div className="h-12 text-center leading-[48px] text-gray-500 dark:text-gray-400">No notifications</div>
          )}
        </div>
      </div>
    </Transition>
  );
}
