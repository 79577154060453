type Props = {
  height?: number;
  className?: string;
};
const LogoSquare = ({ height, className }: Props) => (
  <svg
    className={`w-auto ${className ?? ""}`}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: `${height ?? 20}px`,
    }}
  >
    <path
      d="M64,0 C99.346224,0 128,28.653776 128,64 C128,99.346224 99.346224,128 64,128 C28.653776,128 0,99.346224 0,64 C0,28.653776 28.653776,0 64,0 Z M64,20.5714286 C40.0150623,20.5714286 20.5714286,40.0150623 20.5714286,64 C20.5714286,87.9849377 40.0150623,107.428571 64,107.428571 C87.9849377,107.428571 107.428571,87.9849377 107.428571,64 C107.428571,40.0150623 87.9849377,20.5714286 64,20.5714286 Z M64,45.7142857 C74.0989211,45.7142857 82.2857143,53.9010789 82.2857143,64 C82.2857143,74.0989211 74.0989211,82.2857143 64,82.2857143 C53.9010789,82.2857143 45.7142857,74.0989211 45.7142857,64 C45.7142857,53.9010789 53.9010789,45.7142857 64,45.7142857 Z M57.1428571,52.5714286 C54.6181269,52.5714286 52.5714286,54.6181269 52.5714286,57.1428571 C52.5714286,59.6675874 54.6181269,61.7142857 57.1428571,61.7142857 C59.6675874,61.7142857 61.7142857,59.6675874 61.7142857,57.1428571 C61.7142857,54.6181269 59.6675874,52.5714286 57.1428571,52.5714286 Z"
      id="not_active_light"
      fill="currentColor"
    ></path>
  </svg>
);

export default LogoSquare;
