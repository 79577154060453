import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import Upscope from "./sdk.js";
const UpscopeContext = createContext({
    Upscope: undefined,
    shortId: undefined,
    async getLookupCode() {
        return "";
    },
    listen: (event, callback) => { },
    reset: () => { },
    isSharing: false,
});
export function useUpscope() {
    return useContext(UpscopeContext);
}
export function UpscopeProvider({ children, enabled, ...config }) {
    const [loaded, setLoaded] = useState(false);
    const [sharing, setSharing] = useState(false);
    const [shortId, setShortId] = useState(undefined);
    enabled ??= true;
    useEffect(() => {
        if (enabled && !loaded) {
            setLoaded(true);
            Upscope("init", config);
        }
        else if (!enabled && loaded) {
            Upscope("reset", false);
            setShortId(undefined);
        }
    }, [enabled, loaded, config]);
    useEffect(() => {
        if (enabled && loaded) {
            Upscope("updateConnection", config);
            Upscope("getShortId", (shortId) => {
                setShortId(shortId);
            });
        }
    }, [enabled, loaded, config]);
    useEffect(() => {
        if (loaded) {
            Upscope("on", "sessionStart", () => {
                setSharing(true);
            });
            Upscope("on", "sessionContinue", () => {
                setSharing(true);
            });
            Upscope("on", "sessionEnd", () => {
                setSharing(false);
            });
        }
    }, [loaded]);
    function getLookupCode() {
        return new Promise((resolve) => {
            if (!loaded || !enabled) {
                return resolve(undefined);
            }
            Upscope("getLookupCode", (lookupCode) => {
                resolve(lookupCode);
            });
        });
    }
    function listen(event, callback) {
        Upscope("on", event, callback);
    }
    function reset() {
        Upscope("reset", true);
    }
    return (_jsx(UpscopeContext.Provider, { value: {
            Upscope,
            shortId,
            getLookupCode,
            listen,
            reset,
            isSharing: sharing,
        }, children: children }));
}
export function Masked({ children }) {
    const beforeRef = useRef(null);
    const afterRef = useRef(null);
    useEffect(() => {
        if (beforeRef.current && afterRef.current) {
            let nextSibling = beforeRef.current.nextSibling;
            while (nextSibling && nextSibling !== afterRef.current) {
                nextSibling.dataset.upscopeMasked = true;
                nextSibling = nextSibling.nextSibling;
            }
        }
    }, [beforeRef, afterRef, children]);
    return (_jsxs(_Fragment, { children: [_jsx("script", { ref: beforeRef }), children, _jsx("script", { ref: afterRef })] }));
}
export function NoRemoteControl({ children }) {
    const beforeRef = useRef(null);
    const afterRef = useRef(null);
    useEffect(() => {
        if (beforeRef.current && afterRef.current) {
            let nextSibling = beforeRef.current.nextSibling;
            while (nextSibling && nextSibling !== afterRef.current) {
                nextSibling.dataset.upscopeNoRemoteControl = true;
                nextSibling = nextSibling.nextSibling;
            }
        }
    }, [beforeRef, afterRef, children]);
    return (_jsxs(_Fragment, { children: [_jsx("script", { ref: beforeRef }), children, _jsx("script", { ref: afterRef })] }));
}
