import { classNames } from "../../utils";

type Props = {
  half?: boolean;
  color?: string;
  className?: string;
};

export default function Star({ half, color, className }: Props) {
  half ??= false;
  color ??= "#FF492C";

  if (half) {
    return (
      <svg
        className={classNames("w-4 h-4", className)}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.74512 12.6729V0.215332L11.1861 5.16097L16.6439 5.9541L12.6942 9.80348L13.6264 15.2391L8.74512 12.6729Z"
          fill={color}
          fillOpacity="0.25"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.74642 12.6729L3.86515 15.2391L4.79739 9.80348L0.847656 5.9541L6.30544 5.16097L8.74642 0.215332V12.6729Z"
          fill={color}
        ></path>
      </svg>
    );
  }

  return (
    <svg
      className={classNames("w-4 h-4", className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01986 12.6729L3.13858 15.2391L4.07082 9.80348L0.121094 5.9541L5.57888 5.16097L8.01986 0.215332L10.4608 5.16097L15.9186 5.9541L11.9689 9.80348L12.9011 15.2391L8.01986 12.6729Z"
        fill={color}
      ></path>
    </svg>
  );
}
