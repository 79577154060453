import { createContext, useContext, useEffect, useState } from "react";

const DarkModeContext = createContext({
  darkMode: false,
});

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export default function DarkModeProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (!window.matchMedia) {
      return;
    }

    setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    const listener = (event: MediaQueryListEvent) => {
      setDarkMode(event.matches);
    };

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", listener);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", listener);
    };
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return <DarkModeContext.Provider value={{ darkMode }}>{children}</DarkModeContext.Provider>;
}
