import { Product } from "../../data/models/partials/Product";
import { productName } from "../../formatters/billing";
import Logo from "./Logo";

export default function ProductLogo({ product, className }: { product?: Product; className?: string }) {
  if (!product) {
    return (
      <div className="my-3">
        <Logo height={20} className={className} />
      </div>
    );
  }
  return (
    <div className={`cursor-default ${className ?? ""}`}>
      <div className=" text-xl font-semibold">{productName(product)}</div>
      <div className="opacity-40 text-sm flex items-center space-x-1">
        <span>by</span> <Logo height={10} />
      </div>
    </div>
  );
}
