import { forwardRef } from "react";
import type { PathParameters } from "../../paths";
import Link from "./Link";
import type { DirectLink } from "./Link";
import { buttonClassName } from "./Button";
import Button from "./Button";
import { wrapText } from "../../utils";

type Props = Omit<React.ComponentProps<typeof Button>, "type" | "loading"> & {
  to: PathParameters | Readonly<PathParameters> | DirectLink;
  newTab?: boolean;
};

const ButtonLink = forwardRef<HTMLAnchorElement, Props>(
  ({ to, disabled, children, variant, size, className, newTab, color }: Props, ref): JSX.Element => {
    disabled ??= false;
    size ??= "md";
    variant ??= "primary";
    newTab ??= false;

    function onClick(event: React.MouseEvent<HTMLAnchorElement>) {
      if (disabled) {
        event.preventDefault();
      }
    }

    return (
      <Link
        ref={ref}
        to={to}
        onClick={onClick}
        className={buttonClassName({ loading: false, size, variant, className, color })}
        newTab={newTab}
      >
        {wrapText(children)}
      </Link>
    );
  },
);

export default ButtonLink;
